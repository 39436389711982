// Style Scss
@import 'main/typography';
@import 'main/color';
@import 'main/modal';
@import 'main/style';
@import 'node_modules/react-modal-video/scss/modal-video.scss';


@media screen and (max-width: 500px) {
    .bg-slider {
        right: -100px !important;
    }
}